import { Component, OnInit } from '@angular/core';
import { Contact } from '../model/contact';
import { AllService } from '../services/all.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-content-footer',
  templateUrl: './content-footer.component.html',
  styleUrls: ['./content-footer.component.css']
})
export class ContentFooterComponent implements OnInit {
  contact: Contact = new Contact();

  constructor(private service: AllService, private toastr: ToastrService) {
// this.showSuccess()
  }

  // showSuccess() {
  //   this.toastr.success('Hello world!', 'Toastr fun!');
  // }

  ngOnInit(): void {
  }

  send() {
    this.contact.dateCreation = new Date();
    this.contact.dateModification = new Date();
    this.contact.supprime = false;
    if(this.contact.nom && this.contact.message && this.contact.mail) {
      this.service.saveContact(this.contact).subscribe((res) => {
        if(res.statut == true) {
          console.log(res);
          this.contact = new Contact();
          this.toastr.success("Votre message a été envoyé avec succès.", "TUWINDI VOUS REMERCIE !");
        } else {
          console.log(res);
          this.toastr.error("L'envoie de message échoué.", "Veuillez reéssayer plus tard !");
        }
      }, (err) => {
        console.log(err);
        this.toastr.error("L'envoie de message échoué.", "Veuillez reéssayer plus tard !");
      });
    } else if(!this.contact.nom) {
      console.log("no nom");
      this.toastr.info("Le nom ne doit pas être vide.", "Veuillez renseignez votre nom.");
    } else if(!this.contact.message) {
      console.log("no message");
      this.toastr.info("Le message ne doit pas être vide.", "Veuillez renseignez votre message.");
    } else if(!this.contact.mail) {
      console.log("no mail");
      this.toastr.info("L'adresse email ne doit pas être vide.", "Veuillez renseignez votre adresse email");
    }
  }
}
