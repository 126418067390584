<button (click)="back()" mat-mini-fab color="black" style="position: fixed; z-index: 10; left: 10px; top: 10px;">
  <mat-icon style="margin-right: 10px;">keyboard_backspace</mat-icon>
</button>


<mat-card class="example-card">
  <mat-card-content>
    <div class="parallax" style="background-image: url('{{apiUrl}}image/download/{{actu.photo}}');">
      <div class="centered">
      </div>
    </div> <br>

    <mat-card-title>{{actu.titre}}</mat-card-title>
    <p [innerHTML]="actu.description">

    </p> <br>

    <p style="border-top: 1px solid rgb(143, 143, 143); border-bottom: 1px solid rgb(143, 143, 143);">
      Par {{actu.auteur }} | {{actu.dateCreation | date }} | {{actu.categorie.nom}}
    </p>
  </mat-card-content>
  <mat-card-actions style=" margin-left: 5px;">
    <h3>A propos de l'auteur: {{actu.auteur}}</h3>
  </mat-card-actions>
</mat-card> <br>

<section class="content-item" id="comments">
    <div class="container">
    	<div class="row">
            <div class="col-sm-8">

              <h3>{{actu.commentaire.length}} Commentaires</h3>

              <form>
                <!-- <h3 class="pull-left">Nouveau Commentaire</h3> -->
                <button (click)="sendComment()" type="submit" class="btn btn-normal pull-right">Envoyer</button>
                  <fieldset>
                      <div class="row">
                          <div class="col-sm-3 col-lg-2 hidden-xs">
                            <img class="img-responsive" src="assets/img/user/default-user.jpg" alt="">
                          </div>
                          <div class="form-group col-xs-12 col-sm-9 col-lg-10">
                              <textarea [(ngModel)]="commentaire.commentaire" name="commentaire" class="form-control" id="message" placeholder="Votre message" required=""></textarea>
                          </div>
                      </div>
                  </fieldset>
              </form>

              <!-- COMMENT 4 - START -->
              <div class="media" *ngFor="let comment of actu.commentaire">
                  <a class="pull-left" href="#"><img class="media-object" src="assets/img/user/default-user.jpg" alt=""></a>
                  <div class="media-body">
                      <h4 class="media-heading">{{comment.user.prenom}} {{comment.user.nom}}</h4>
                      <p>{{comment.commentaire}}</p>
                      <ul class="list-unstyled list-inline media-detail pull-left">
                          <li><i class="fa fa-calendar"></i>{{comment.dateCreation | date: 'dd/MM/YYYY'}}</li>
                      </ul>

                  </div>
              </div>
              <!-- COMMENT 4 - END -->



            </div>
        </div>
    </div>
</section>
<br><br>

<h2>Articles similaires</h2>

<div fxLayout="column" fxLayout.gt-sm="row wrap">
  <div fxFlex="32.5" class="flex-p" *ngFor="let act of actualites">
    <mat-card class="custom-card">
        <mat-card-header (click)="goToDetail(act)" style="background-color: black; width: 100%; padding: 0px;" class="p-1" fxLayoutAlign="space-between center">
            <mat-card-title class="m-0"><h3 style="color: rgb(255, 255, 255);" class="actTitre">{{act.titre}}</h3></mat-card-title>
        </mat-card-header>
        <mat-card-content (click)="goToDetail(act)" class="p-">
          <div class="actuImage" style="background-image: url('{{apiUrl}}image/download/{{act.photo}}');">

          </div>
            <p class="actDescription" [innerHTML]="act.description">

            </p>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions fxLayoutAlign="end center">
            <button (click)="comment(act)" mat-icon-button>
              <span> {{act.commentaire.length}} </span>
              <mat-icon>insert_comment</mat-icon></button>
            <button mat-icon-button><mat-icon>share</mat-icon></button>
        </mat-card-actions>
    </mat-card>
  </div>

</div> <br><br>



<app-copy-right></app-copy-right>
