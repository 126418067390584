import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article } from '../model/article';
import { Commentaire } from '../model/commentaire';
import { Contact } from '../model/contact';

@Injectable({
  providedIn: 'root'
})
export class AllService {
apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getAllResultat(id): Observable<any> {
    return this.http.get(this.apiUrl + 'resultats/bytechno/' + id);
  }
  getAllPartenaire(): Observable<any> {
    return this.http.get(this.apiUrl + 'partenaires');
  }
  getAllArticle(): Observable<any> {
    return this.http.get(this.apiUrl + 'articles/actualites');
  }
  getArtticleById(id): Observable<any> {
    return this.http.get(this.apiUrl + 'articles/byId/' + id);
  }
  getAllArticleWithoutAlaUne(): Observable<any> {
    return this.http.get(this.apiUrl + 'articles/withoutalaune');
  }
  getByType(type): Observable<any> {
    return this.http.get(this.apiUrl + 'articles/type/' + type);
  }
  getAllProjet(): Observable<any> {
    return this.http.get(this.apiUrl + 'projets');
  }
  getAllInitiative(type): Observable<any> {
    return this.http.get(this.apiUrl + 'projets/initiative/' + type);
  }
  getAlaUneArticle(): Observable<any> {
    return this.http.get(this.apiUrl + 'articles/alaune');
  }
  getWithoutCurrentArticle(id): Observable<any> {
    return this.http.get(this.apiUrl + 'articles/withoutcurrent/' + id);
  }
  getAllTechnologie(): Observable<any> {
    return this.http.get(this.apiUrl + 'technologies');
  }
  getAllCaptureByTechno(techId): Observable<any> {
    return this.http.get(`${this.apiUrl}medias/${techId}`);
  }
  getAllFonctionnaliteByTechno(techId): Observable<any> {
    return this.http.get(`${this.apiUrl}fonctionnalites/${techId}`);
  }
  getAllCategorie(): Observable<any> {
    return this.http.get(this.apiUrl + 'categories');
  }
  getAllProjetCategorie(id): Observable<any> {
    return this.http.get(this.apiUrl + 'projets/categorie/' + id);
  }
  getAllProjetCategorieAndType(id, type): Observable<any> {
    return this.http.get(this.apiUrl + 'projets/categorie/type/' + id + '/' + type);
  }
  saveComment(commentaire: Commentaire): Observable<any> {
    return this.http.post(this.apiUrl + 'commentaires/create', commentaire);
  }
  saveContact(contact: Contact): Observable<any> {
    return this.http.post(`${this.apiUrl}contacts/create`, contact);
  }
}
