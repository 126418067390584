import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Fonctionalite } from '../model/fonctionalite';
import { Resultat } from '../model/resultat';
import { Techonologie } from '../model/technologie';
import { AllService } from '../services/all.service';
declare var jQuery: any;

@Component({
  selector: 'app-detail-technologie',
  templateUrl: './detail-technologie.component.html',
  styleUrls: ['./detail-technologie.component.css']
})
export class DetailTechnologieComponent implements OnInit {

  technologie: Techonologie;
  imUrl: string;
  apiUrl = environment.apiUrl;
  captures: any[] = [];
  fonctionalites: Fonctionalite[] = [];
  resultats: Resultat[] = [];

  constructor(private route: ActivatedRoute,
    private router: Location,
    private service: AllService) {

    this.route.queryParams.subscribe((query) => {
      this.technologie = JSON.parse(query.technologie);
      this.getCapture(this.technologie.id);
      this.getTechno(this.technologie.id);
      this.getResultat(this.technologie.id);
      console.log(this.technologie);
      window.scroll(0,0);
      this.imUrl = this.apiUrl + "image/download/" + this.technologie.path;
    });
   }

  ngOnInit(): void {


  }


  getResultat(id) {
    this.service.getAllResultat(id).subscribe((res) => {
      this.resultats = res;
      console.log("resultat", res);
    });
  }

  getCapture(id) {
    this.service.getAllCaptureByTechno(id).subscribe((res) => {
      console.log('captures', res);
      this.captures = res;
    });
  }

  getTechno(id) {
    this.service.getAllFonctionnaliteByTechno(id).subscribe((res) => {
      console.log(res);
      this.fonctionalites = res;
    });
  }

  back() {
    this.router.back();
  }
}
