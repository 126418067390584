import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AccueilComponent } from './accueil/accueil.component';
import { ActualiteComponent } from './actualite/actualite.component';
import { AllActualiteComponent } from './all-actualite/all-actualite.component';
import { AllProjetComponent } from './all-projet/all-projet.component';
import { AnimatedComponent } from './animated/animated.component';
import { BurauxComponent } from './buraux/buraux.component';
import { CommentPopComponent } from './comment-pop/comment-pop.component';
import { ContactComponent } from './contact/contact.component';
import { ContentFooterComponent } from './content-footer/content-footer.component';
import { CopyRightComponent } from './copy-right/copy-right.component';
import { CornerfluidComponent } from './cornerfluid/cornerfluid.component';
import { DetailActuComponent } from './detail-actu/detail-actu.component';
import { DetailProjetComponent } from './detail-projet/detail-projet.component';
import { DetailTechnologieComponent } from './detail-technologie/detail-technologie.component';
import { DomianeInterventionComponent } from './domiane-intervention/domiane-intervention.component';
import { HeaderComponent } from './header/header.component';
import { IachievementComponent } from './iachievement/iachievement.component';
import { IdentiteComponent } from './identite/identite.component';
import { ListActualiteComponent } from './list-actualite/list-actualite.component';
import { ListProjetComponent } from './list-projet/list-projet.component';
import { ListTechnologieComponent } from './list-technologie/list-technologie.component';
import { LoginComponent } from './login/login.component';
import { OffreComponent } from './offre/offre.component';
import { OpportuniteComponent } from './opportunite/opportunite.component';
import { PartenaireComponent } from './partenaire/partenaire.component';
import { ProjetComponent } from './projet/projet.component';
import { ProposComponent } from './propos/propos.component';
import { RegisterComponent } from './register/register.component';
import { SliderTestComponent } from './slider-test/slider-test.component';
import { TechnologieComponent } from './technologie/technologie.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SharedModule } from './shared/shared.module';
import { Interceptor } from './Interceptor/interceptor';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CornerfluidComponent,
    DomianeInterventionComponent,
    IdentiteComponent,
    IachievementComponent,
    TechnologieComponent,
    ActualiteComponent,
    OpportuniteComponent,
    ProjetComponent,
    ContactComponent,
    PartenaireComponent,
    ContentFooterComponent,
    CopyRightComponent,
    AccueilComponent,
    DetailActuComponent,
    DetailProjetComponent,
    ListActualiteComponent,
    ListProjetComponent,
    ListTechnologieComponent,
    OffreComponent,
    AllProjetComponent,
    BurauxComponent,
    DetailTechnologieComponent,
    AllActualiteComponent,
    ProposComponent,
    AnimatedComponent,
    SliderTestComponent,
    CommentPopComponent,
    LoginComponent,
    RegisterComponent
  ],
  imports: [
    ToastrModule.forRoot(),
    NgxUsefulSwiperModule,
    HttpClientModule,
    BrowserModule,
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  exports: [],
  providers: [ { provide:HTTP_INTERCEPTORS,
     useClass:Interceptor, multi:true},],
  bootstrap: [AppComponent, ]
})
export class AppModule { }
