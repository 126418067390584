import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommentPopComponent } from '../comment-pop/comment-pop.component';
import { LoginComponent } from '../login/login.component';
import { Article } from '../model/article';
import { Commentaire } from '../model/commentaire';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-detail-actu',
  templateUrl: './detail-actu.component.html',
  styleUrls: ['./detail-actu.component.css']
})
export class DetailActuComponent implements OnInit {

  actu: Article;
  apiUrl = environment.apiUrl;
  actualites: Article[] = [];
  commentaire: Commentaire = new Commentaire();

  constructor(private route: ActivatedRoute,
    private router: Location,
    private nav: Router,
    private service: AllService,
    public dialog: MatDialog,
    private toast: ToastrService) {
    this.route.queryParams.subscribe((query) => {
      this.actu = JSON.parse(query.actu);
      console.log(this.actu);
    });
  }

  ngOnInit(): void {
    this.getActuWithoutCurrent();
    // console.log("currentUser", JSON.parse(sessionStorage.getItem('currentUser')));

    // localStorage.removeItem('userToken');
  }

  sendComment() {
   if(this.commentaire.commentaire != "" && this.commentaire.commentaire != null && localStorage.getItem('userToken')) {
    this.commentaire.article = this.actu;
    this.commentaire.dateCreation = new Date();
    this.commentaire.dateModification = new Date();
    this.commentaire.supprime = false;
    this.commentaire.user = JSON.parse(localStorage.getItem('currentUser'));
    setTimeout(() => {
      console.log(this.commentaire);

      this.service.saveComment(this.commentaire).subscribe((res) => {
        console.log(res);
        if(res.statut == true) {
          this.getActuByIId();
          this.commentaire = new Commentaire();
          this.ngOnInit();
        }
      }, (err) => {
        console.log(err);

      });
    }, 500);
   } else if(!localStorage.getItem('')) {
    this.openDialog(this.actu, LoginComponent);
   } else if(this.commentaire.commentaire == "" && this.commentaire.commentaire == null) {
    this.toast.info("Veuillez renseigner le commentaire !");
   }
  }

  getActuByIId() {
    this.service.getArtticleById(this.actu.id).subscribe((res) => {
      if(res) {
        this.actu = res;
      }
    });
  }

  openDialog(article: Article, page): void {
    let dialogRef = this.dialog.open(page, {
      data: article,
      panelClass: 'my-class'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  back() {
    this.router.back();
  }

  getActuWithoutCurrent() {
    this.service.getWithoutCurrentArticle(this.actu.id).subscribe((res) => {
      console.log("without", res);
      this.actualites = res;
      window.scroll(0,0);
    });
  }

  goToDetail(actu) {
    let nav: NavigationExtras = {
      queryParams: {
        actu: JSON.stringify(actu)
      }
    };
    this.nav.navigate(['detail-actu'], nav);
    this.service.getWithoutCurrentArticle(actu.id).subscribe((res) => {
      console.log("without", res);
      this.actualites = res;
      window.scroll(0,0);
    });
  }

  comment(article: Article) {
    let token = localStorage.getItem('userToken');
    if(token) {
      this.openDialog(article, CommentPopComponent);
    } else {
      this.openDialog(article, LoginComponent);
    }
    // this.service.saveComment(article).subscribe((com) => {
    //   console.log(com);
    // });
  }

}
