<section id="section-0697cb9" class="fw-main-row section ds section_padding_top_0 section_padding_bottom_0 columns_padding_30 background_cover " style="background-image:url(assets/img/2019/05/footer_alt_bg.jpg);">
  <h3 class="hidden"> Footer</h3>
  <div class="top_corner_body">
   <div class="container">
    <div class="row">
     <div id="column-037de34" class="col-xs-12 col-md-4 left fw-column" >
      <div class="fw-divider-space hidden-sm hidden-xs" style="margin-top: 153px;"></div>
      <div class="fw-divider-space hidden-lg hidden-md" style="margin-top: 60px;"></div>
      <div class="special-heading text-center">
       <h5 class="section_header margin_0">
        <span class="lightfont medium text-transform-none"> Documents importants	</span>
       </h5>
      </div>
      <div class="fw-divider-space" style="margin-top: 30px;"></div>
      <div class=" services-footer-menu">
       <div class="simple-list text-center list-1">
        <ul class="no-bullets">
         <li class="list-item">
          <a > Politique G&eacute;n&eacute;rale </a>
         </li>
         <li class="list-item">
          <a > Code de d&eacute;ontologie </a>
         </li>
         <li class="list-item">
          <a > Plan Strat&eacute;gique quinqu&eacute;nal </a>
         </li>
         <li class="list-item">
          <a > Manuel de proc&eacute;dure	</a>
         </li>
         <li class="list-item">
          <a > Rapports d&apos;activit&eacute;s annuels	</a>
         </li>
         <li class="list-item">
          <a > Rapports d&apos;&eacute;tudes	</a>
         </li>
        </ul>
       </div>
      </div>
      <div class="fw-divider-space hidden-sm hidden-xs" style="margin-top: 90px;"></div>
      <div class="fw-divider-space hidden-lg hidden-md" style="margin-top: 60px;"></div>
     </div>
     <div id="column-5f4772a" class="col-xs-12 col-md-4 text-center ls ms left fw-column">
      <div class="padding_30 left">
       <div class="img-wrap">
        <img src="assets/img/2019/06/footer-logo.png" alt="assets/img/2019/06/footer-logo.png" class="hidden-md hidden-sm hidden-xs footer-alt-logo " /></div>
       <div class="fw-divider-space hidden-sm hidden-xs" style="margin-top: 93px;"></div>
       <div class="special-heading text-center">
        <h5 class="section_header margin_0">
         <span class="darkgrey medium text-transform-none">
          <h1>Nous contacter</h1>
         </span>
        </h5>
       </div>
       <div class="fw-divider-space " style="margin-top: 20px;"></div>
       <div class="form-wrapper text-center fields_with_background columns_padding_10">
        <form data-fw-form-id="fw_form" method="post" class="fw_form_fw_form" data-fw-ext-forms-type="contact-forms">
         <input type="hidden" name="fwf" value="fw_form"/>
         <input type="hidden" id="_nonce_42964dfcb285dd399d6703c350374a9b" name="_nonce_42964dfcb285dd399d6703c350374a9b" value="7e6c340331" />
         <input type="hidden" name="_wp_http_referer" value="/deepdigital-um/home-alt-2/?home_demo=1&amp;footer=2&amp;copyrights=1&amp;header=7"/>
         <input type="hidden" name="fw_ext_forms_form_type" value="contact-forms" />
         <input type="hidden" name="fw_ext_forms_form_id" value="a8c3a35e2877252361b42280d305869a" />
         <div class="wrap-forms">
          <div class="row"></div>
          <div class="row">
           <div class="col-xs-12 form-builder-item">
            <div class="form-group has-placeholder text-center">
             <label for="id-6"> Nom complet	<sup>*</sup> </label>
             <input [(ngModel)]="contact.nom" class="form-control text-center" type="text" name="text_c99efa5" placeholder="Nom complet" value="" id="id-6" required="required">
            </div>
           </div>
          </div>
          <div class="row">
           <div class="col-xs-12 form-builder-item">
            <div class="form-group has-placeholder text-center">
             <label for="id-7">Email	<sup>*</sup></label>
             <input [(ngModel)]="contact.mail" class="form-control text-center" type="text" name="email_f2582cd" placeholder="Email" value="" id="id-7" required="required">
            </div>
           </div>
          </div>
          <div class="row">
            <div class="col-xs-12 form-builder-item">
             <div class="form-group has-placeholder text-center">
              <label for="id-5">Objet <sup>*</sup> </label>
              <input [(ngModel)]="contact.objet" class="form-control text-center" rows="4" name="textarea_c76086d" placeholder="Objet" id="id-5" required="required">
             </div>
            </div>
           </div>
          <div class="row">
           <div class="col-xs-12 form-builder-item">
            <div class="form-group has-placeholder text-center">
             <label for="id-8">Message	<sup>*</sup>	</label>
             <textarea [(ngModel)]="contact.message" class="form-control text-center" rows="3" name="textarea_90ee6ae" placeholder="Message" id="id-8" required="required"></textarea>
            </div>
           </div>
          </div>
          <div class="row"></div>
         </div>
         <div class="wrap-forms wrap-forms-buttons topmargin_20">
          <div class="row">
           <div class="col-sm-12 wrap-buttons text-center">
            <input (click)="send()" class="btn theme_button wide_button inverse color1 medium_height" value="Envoyer" />
           </div>
          </div>
         </div>
        </form>
       </div>
       <div class="fw-divider-space hidden-sm hidden-xs" style="margin-top: 110px;"></div>
      </div>
     </div>

     <div class="fw-divider-space " style="margin-top: 30px;"></div>

    </div>
    <div class="fw-divider-space " style="margin-top: 90px;"></div>
   </div>
  </div><!-- eof .container -->
  <div class="bottom_corner_body"></div>
 </section>
