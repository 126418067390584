import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Techonologie } from '../model/technologie';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-technologie',
  templateUrl: './technologie.component.html',
  styleUrls: ['./technologie.component.css']
})
export class TechnologieComponent implements OnInit {

  technologies: Techonologie[] = [];
  technologies1: Techonologie[] = [];
  technologies2: Techonologie[] = [];
  apiUrl = environment.apiUrl;

  constructor(private service: AllService, private router: Router) { }

  ngOnInit(): void {
    this.getAllTechnologie();
  }

  getAllTechnologie() {
    this.service.getAllTechnologie().subscribe((res) => {
      // console.log(res)
      this.technologies = res;
      let reste = this.technologies.length % 2;
      let quotient = (this.technologies.length - reste)/2;

      // push for first array
      for(let i=0; i<quotient + reste; i++) {
        this.technologies1.push(this.technologies[i]);
      }

      // pus for second array
      for(let i=quotient + reste; i<this.technologies.length; i++) {
        this.technologies2.push(this.technologies[i]);
      }
    });
  }

  goToDetail(technologie: Techonologie) {
    let nav: NavigationExtras = {
      queryParams: {
        technologie: JSON.stringify(technologie)
      }
    }
    localStorage.setItem('idForScroll', 'technologie');
    this.router.navigate(['detail-technologie'], nav);
  }

}
