<section id="section-e5e6a36" class="fw-main-row section ls section_padding_top_0 section_padding_bottom_0 columns_padding_0 fullwidth-section horizontal-paddings-0 columns-height" >
  <h3 class="hidden"> Icon boxes</h3>
  <div class="top_corner_body"></div>
  <div class="container-fluid">
   <div class="row">
    <div id="column-e20b4b8" class="col-xs-12 col-md-4 with_background left fw-column" style="background-color: white;">
      <div class="padding_80 left">
       <div id="box-856a872" class="icon-box icon_right icon-boxes main-color">
        <div class="box_icon color-main">
         <img src="assets/img/2019/05/icon-box-3.png" alt="7452">
        </div>
        <div class="box-wrap">
         <h1 class="box-heading">01.</h1>
        </div>
       </div>
       <div class="fw-divider-space " style="margin-top: 20px;"></div>
       <div class="special-heading text-left">
        <h1 class="section_header margin_0">
         <span class="darkgrey medium text-transform-none"> Opportunités	</span>
        </h1>
       </div>
       <div class="fw-divider-space " style="margin-top: 20px;"></div>
       <div class="text-block shortcode ">
        <p>In reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
       </div>
      </div>
     </div>
    <div id="column-2a0ba34" class="col-xs-12 col-md-4 cs left fw-column" style="background-color: rgb(92, 92, 92); color: white;">
     <div class="padding_80 left">
      <div id="box-bdcdc6b" class="icon-box icon_right icon-boxes dark-color">
       <div class="box_icon color-main">
        <img src="assets/img/2019/05/icon-box-2.png" alt="7451">
       </div>
       <div class="box-wrap">
        <h1 class="box-heading" style="color: white;"> 02.</h1>
       </div>
      </div>
      <div class="fw-divider-space " style="margin-top: 20px;"></div>
      <div class="special-heading text-left">
       <h1 class="section_header margin_0">
        <span class="lightfont medium text-transform-none">	Partenariats </span>
       </h1>
      </div>
      <div class="fw-divider-space " style="margin-top: 20px;"></div>
      <div class="text-block shortcode ">
       <p>Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat duis aute irure dolor.</p>
      </div>
     </div>
    </div>
    <div id="column-e20b4b8" class="col-xs-12 col-md-4 with_background left fw-column" style="background-color: white;">
     <div class="padding_80 left">
      <div id="box-856a872" class="icon-box icon_right icon-boxes main-color">
       <div class="box_icon color-main">
        <img src="assets/img/2019/05/icon-box-3.png" alt="7452">
       </div>
       <div class="box-wrap">
        <h1 class="box-heading">03.</h1>
       </div>
      </div>
      <div class="fw-divider-space " style="margin-top: 20px;"></div>
      <div class="special-heading text-left">
       <h1 class="section_header margin_0">
        <span class="darkgrey medium text-transform-none"> Investir dans nos projets	</span>
       </h1>
      </div>
      <div class="fw-divider-space " style="margin-top: 20px;"></div>
      <div class="text-block shortcode ">
       <p>In reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
      </div>
     </div>
    </div>
   </div>
  </div>
  <div class="bottom_corner_body"></div>
 </section>
