<section id="section-89cff66" class="fw-main-row section ls section_padding_top_150 section_padding_bottom_150 columns_padding_15 " >
  <h3 class="hidden"> Steps 3</h3>
  <div class="top_corner_body"></div>
  <div class="container">
   <div class="row">
    <div id="column-a13c77b" class="col-xs-12 text-center left col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 fw-column" >
     <div class="special-heading text-center">
      <h2 class="section_header margin_0 big_title ">
        <span class=" medium text-transform-none">
         Domaines d&apos;intervention</span>
      </h2>
     </div>
     <div class="fw-divider-space " style="margin-top: 47px;"></div>
     <div class="text-block shortcode big_text">
      <p style="text-align: justify;">Tuwindi intervient dans le secteur de la gouvernance, de la citoyenneté, des droits de l’homme et de la démocratie.
        Notre approche consiste à consolider la démocratie et le développement social et économique des actions du gouvernement, des organisations de la société civile, des médias et des partis politiques.</p>
     </div>
    </div>
    <div id="column-874f458" class="col-xs-12 left fw-column">
     <div class="fw-theme-steps steps-3">
      <div class="fw-theme-steps-wrap">
       <div class="vertical-item text-center color1">
        <div class="item-img">
         <img width="60" height="58" src="assets/img/2019/05/steps_3_1.png" class="attachment-deepdigital-square size-deepdigital-square" alt="" loading="lazy" />
        </div>
        <div class="item-dot"><span></span></div>
        <h1 class="item-title">Gouvernance & &Eacute;lection</h1>
        <p class="item-text">Nous développons des applications, web et mobile, pour renforcer la qualité de la gouvernance et veiller à la transparence et l’intégrité des élections.</p>
       </div>
       <div class="vertical-item text-center color2">
        <div class="item-img">
         <img width="60" height="60" src="assets/img/2019/05/steps_3_2.png" class="attachment-deepdigital-square size-deepdigital-square" alt="" loading="lazy"/>
        </div>
        <div class="item-dot"><span></span></div>
        <h1 class="item-title">Developpement Médias</h1>
        <p class="item-text">Nous renforçons les capacités des acteurs et organes de médias pour accroître leurs professionnalismes et promouvoir la liberté d’expression.</p>
       </div>
       <div class="vertical-item text-center color3">
        <div class="item-img">
         <img width="60" height="44" src="assets/img/2019/05/steps_3_3.png" class="attachment-deepdigital-square size-deepdigital-square" alt="" loading="lazy" />
        </div>
        <div class="item-dot"><span></span></div>
        <h1 class="item-title">Economie numerique</h1>
        <p class="item-text">Nous renforçons les capacités des acteurs et organes de médias pour accroître leurs professionnalismes et promouvoir la liberté d’expression.</p>
       </div>
       <!-- <div class="vertical-item text-center color4">
        <div class="item-img">
         <img width="50" height="50" src="assets/img/2019/05/steps_3_4.png" class="attachment-deepdigital-square size-deepdigital-square" alt="" loading="lazy" />
        </div>
        <div class="item-dot"><span></span></div>
        <h1 class="item-title">Support</h1>
        <p class="item-text">Quis trud exercitation ullamco laboris nisi ut aliquip.</p>
       </div>-->
      </div>
     </div>
    </div>
    <div id="column-f23eaca" class="col-xs-12 text-center left fw-column">
     <a href="#contact" target="_self" class="wide_button theme_button inverse color1 medium_height">
      Travaillons ensemble maintenant
     </a>
    </div>
   </div>
  </div>
  <div class="bottom_corner_body"></div>
 </section>
