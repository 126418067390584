import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Article } from '../model/article';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-all-actualite',
  templateUrl: './all-actualite.component.html',
  styleUrls: ['./all-actualite.component.css']
})
export class AllActualiteComponent implements OnInit {

  actualites: Article[] = [];
  apiUrl = environment.apiUrl;

  constructor(private nav: Router, private service: AllService, private location: Location) { }

  ngOnInit(): void {
    this.getAllActualite();
  }

  getAllActualite() {
    this.service.getAllArticle().subscribe((actu) => {
      this.actualites = actu;
      console.log(actu);
    });
  }

  goToDetail(actu) {
    let nav: NavigationExtras = {
      queryParams: {
        actu: JSON.stringify(actu)
      }
    };
    this.nav.navigate(['detail-actu'], nav);
    this.service.getWithoutCurrentArticle(actu.id).subscribe((res) => {
      console.log("without", res);
      this.actualites = res;
      window.scroll(0,0);
    });
  }

  back() {
    this.location.back();
  }
}
