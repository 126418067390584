<section id="section-acbf473" class="fw-main-row section ls ms section_padding_top_150 section_padding_bottom_150 columns_padding_80 fullwidth-section table_section table_section_md  half_section image_cover_hidden_sm" >
  <h3 class="hidden"> Contact Us</h3>
  <div class="top_corner_body"></div>
  <!--<div class="cover_image" style="background-image:url('assets/img/2019/05/half-img.jpg')">-->
  <div class="image_cover image_cover_left" style="background-image:url('assets/img/2019/05/half-img.jpg')"></div>
  <div class="container-fluid">
   <div class="row">
    <div id="column-2c66a00" class="col-xs-12 col-md-6 left fw-column"></div>
    <div id="column-10b6d0d" class="col-xs-12 col-md-6 left fw-column">
     <div class="special-heading text-left">
      <h2 class="section_header margin_0 big_title ">
       <span class=" medium text-transform-none"> Nous contacter	</span>
      </h2>
     </div>
     <div class="fw-divider-space " style="margin-top: 45px;"></div>
     <div class="form-wrapper text-left fields_with_background columns_padding_10">
      <form data-fw-form-id="fw_form" method="post" action="../../../external.html?link=http://webdesign-finder.com/deepdigital-um/home-alt-2/?home_demo=1&amp;footer=2&amp;copyrights=1&amp;header=7" class="fw_form_fw_form" data-fw-ext-forms-type="contact-forms"><input type="hidden" name="fwf" value="fw_form" /><input type="hidden" id="_nonce_9bba3ecfa45c7ed9872451f35b5d0165" name="_nonce_9bba3ecfa45c7ed9872451f35b5d0165" value="7e6c340331" /><input type="hidden" name="_wp_http_referer" value="/deepdigital-um/home-alt-2/?home_demo=1&amp;footer=2&amp;copyrights=1&amp;header=7" /><input type="hidden" name="fw_ext_forms_form_type" value="contact-forms" /><input type="hidden" name="fw_ext_forms_form_id" value="317c07c1b78bb9ed554f85d9a7780359" /><div class="wrap-forms">
       <div class="row"></div>
       <div class="row">
        <div class="col-xs-12 col-md-6 form-builder-item">
         <div class="form-group has-placeholder text-center">
          <label for="id-1">Nom <sup>*</sup></label>
          <input [(ngModel)]="contact.nom" class="form-control text-center" type="text" name="text_426a6ba" placeholder="Nom complet" value="" id="id-1" required="required">
         </div>
        </div>
        <div class="col-xs-12 col-md-6 form-builder-item">
         <div class="form-group has-placeholder text-center">
          <label for="id-2">Structure<sup>*</sup></label>
          <input [(ngModel)]="contact.structure" class="form-control text-center" type="text" name="text_5a891b7" placeholder="Structure" value="" id="id-2" required="required">
         </div>
        </div>
       </div>
       <div class="row">
        <div class="col-xs-12 col-md-6 form-builder-item">
         <div class="form-group has-placeholder text-center">
          <label for="id-3">Email	<sup>*</sup> </label>
          <input [(ngModel)]="contact.mail" class="form-control text-center" type="text" name="email_0979927" placeholder="Email" value="" id="id-3" required="required">
         </div>
        </div>
        <div class="col-xs-12 col-md-6 form-builder-item">
         <div class="form-group has-placeholder text-center">
          <label for="id-4">T&eacute;l&eacute;phone <sup>*</sup> </label>
          <input [(ngModel)]="contact.telephone" class="form-control text-center" type="number" name="text_3d91985" placeholder="Telephone" value="" id="id-4" required="required">
         </div>
        </div>
       </div>
       <div class="row">
        <div class="col-xs-12 form-builder-item">
         <div class="form-group has-placeholder text-center">
          <label for="id-5">Objet <sup>*</sup> </label>
          <input [(ngModel)]="contact.objet" class="form-control text-center" rows="4" name="textarea_c76086d" placeholder="Objet" id="id-5" required="required">
         </div>
        </div>
       </div>
       <div class="row">
        <div class="col-xs-12 form-builder-item">
         <div class="form-group has-placeholder text-center">
          <label for="id-5">Message <sup>*</sup> </label>
          <textarea [(ngModel)]="contact.message" class="form-control text-center" rows="4" name="textarea_c76086d" placeholder="Message" id="id-5" required="required"></textarea>
         </div>
        </div>
       </div>
       <div class="row"></div>
      </div>
       <div class="wrap-forms wrap-forms-buttons topmargin_20">
        <div class="row">
         <div class="col-sm-12 wrap-buttons text-left">
          <input class="btn theme_button wide_button inverse color1 medium_height" (click)="send()" value="Envoyer Message" />
          <input class="theme_button wide_button inverse color1 medium_height" type="reset" value="Clear" />
         </div>
        </div>
       </div>
      </form>
     </div>
    </div>
   </div>
  </div>
  <div class="bottom_corner_body"></div>
 </section>
