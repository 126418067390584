import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { Partenaire } from '../model/partenaire';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-partenaire',
  templateUrl: './partenaire.component.html',
  styleUrls: ['./partenaire.component.css']
})
export class PartenaireComponent implements OnInit {

  partenaires: Partenaire[] = [];
  apiUrl = environment.apiUrl;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    autoplay: true,
    navSpeed: 700,
    center: true,
    margin:0,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3
      },
      360: {
        items: 3
      },
      400: {
        items: 3
      },
      500: {
        items: 4
      },
      600: {
        items: 4
      },
      740: {
        items: 5
      },
      940: {
        items: 6
      }
    },
    nav: true
  }

    // slides = [
    //   {id: 1, img: "https://dummyimage.com/350x150/423b42/fff"},
    //   {id: 2, img: "https://dummyimage.com/350x150/2a2b7a/fff"},
    //   {id: 3, img: "https://dummyimage.com/350x150/1a2b7a/fff"},
    //   {id: 4, img: "https://dummyimage.com/350x150/7a2b7a/fff"},
    //   {id: 5, img: "https://dummyimage.com/350x150/9a2b7a/fff"},
    //   {id: 6, img: "https://dummyimage.com/350x150/5a2b7a/fff"},
    //   {id: 6, img: "https://dummyimage.com/350x150/4a2b7a/fff"}
    // ];


  constructor(private service: AllService) { }

  ngOnInit(): void {
    this.getPartenaire();
  }

  getPartenaire() {
    this.service.getAllPartenaire().subscribe((res) => {
      console.log(res);
      this.partenaires = res;
    });
  }
}
