import { Article } from "./article";
import { User } from "./user";

export class Commentaire {
  id?: number;
  commentaire: string;
  article: Article;
  supprime: boolean;
  dateCreation: Date;
  dateModification: Date;
  user: User;
}
