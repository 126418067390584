<button (click)="back()" mat-mini-fab color="black" style="position: fixed; z-index: 10; left: 10px; top: 10px;">
  <mat-icon style="margin-right: 10px;">keyboard_backspace</mat-icon>
</button>

<mat-card class="example-card">
  <!-- <img mat-card-image src="{{apiUrl}}image/download/{{projet.path}}" alt="Photo projet"> -->
  <mat-card-content>
    <div class="parallax" style="background-image: url('{{apiUrl}}image/download/{{projet.path}}');">

    </div> <br>
    <mat-card-title style="margin-left:7%">{{projet.nom}}</mat-card-title>
    <div class="row">
      <div class="col-lg-6">
        <div style="margin-left:15%">
          <h3>DESCRIPTION</h3>
          <div [innerHTML]="projet.description">

          </div> <br>

          <h3>OBJECTIFS</h3>
          <div [innerHTML]="projet.objectifs">

          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div style="margin-left: 25%;">

          <h3>BENEFICIAIRES</h3>
          <div [innerHTML]="projet.beneficiaires">

          </div> <br>

          <h3>LIEU</h3>
          <div [innerHTML]="projet.lieu">

          </div> <br>

          <h3>RESPONSABLE</h3>
          <div [innerHTML]="projet.responsable">

          </div>
        </div>
      </div>
    </div>


  </mat-card-content>
    <mat-card-actions style=" margin-left: 5px;">
  </mat-card-actions>
</mat-card>

<br><br><br>

<h3>Les <b>technologies</b> de <span>{{projet.nom}}</span> </h3>
<div class="tecno" fxLayout="column" fxLayout.gt-sm="row wrap">
  <div (click)="goToDetail(tec)" fxFlex="20" class="flex-p" *ngFor="let tec of projet.technologie">
    <mat-card class="custom-card">
        <mat-card-header class="bg-warn p-1" fxLayoutAlign="space-between center">
            <mat-card-title class="m-0"><h3>{{tec.titre}}</h3></mat-card-title>
        </mat-card-header>
        <div  class="bg" style="background-image: url('{{apiUrl}}image/download/{{tec.bgImage}}'); cursor: pointer;">
        </div>
        <mat-card-content class="p-">
            <p [innerHTML]="tec.description">

            </p>
        </mat-card-content>
    </mat-card>
  </div>
</div>
<br><br>




<mat-divider></mat-divider>

<h3>Les <b>partenaires</b> de <span>{{projet.nom}}</span> </h3>

<div class="part" fxLayout="column" fxLayout.gt-sm="row wrap">
  <div fxFlex="10" class="flex-p" style="margin-right: 15px;" *ngFor="let part of projet.partenaire">
    <mat-card class="custom-card">
        <mat-card-header class="bg-warn p-1" fxLayoutAlign="space-between center">
            <mat-card-title class="m-0"><h3>{{part.nom}}</h3></mat-card-title>
        </mat-card-header>
        <div>
          <img style="height: 100px;" src="{{apiUrl}}image/download/{{part.logo}}" alt="2">
        </div>
    </mat-card>
  </div>
</div>
<br><br><br>


<app-copy-right></app-copy-right>
