<section class="cs page_copyright section_padding_15" style="background-image: url(assets/img/2018/09/footer_bg.png)">
  <h3 class="hidden">Page Copyright</h3>
  <div class="container-fluid">
   <div class="row">
    <div class="col-sm-12 text-center">
     <p>© Copyright {{annee}} | Tuwindi Foundation | All Rights Reserved</p>
    </div>
   </div>
  </div>
 </section>
