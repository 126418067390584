<body class="page-template page-template-page-templates page-template-full-width-no-footer page-template-page-templatesfull-width-no-footer-php page page-id-7326 theme-deepdigital woocommerce-no-js masthead-fixed full-width home singular light-body">
  <div id="canvas" class="wide">
   <div id="box_wrapper">
    <div class="top" id="top"></div>

    <app-header style="position: fixed; z-index: 10; width: 100%;" id="header"></app-header>
    <div class="centered">
      <span style="font-weight: normal;">BIENVENUE CHEZ TUWINDI</span> <br><br>
      We provide tech solutions <br/><br/> to make our cities smart
    </div>

    <div class="container-fluid bg" style="background-image: url('assets/img/2019/05/img_25.jpg');">

    </div>
    <!-- <div (click)="allProjet()" id="column-f23eaca" class="col-xs-12 text-center left fw-column">
      <a target="_self" class="wide_button theme_button inverse color1 medium_height">
       Voir tous nos projets
      </a>
     </div> -->

    <div class="fw-page-builder-content">
     <section id="section-2c5c5b8" class="fw-main-row section ds section_padding_top_0 section_padding_bottom_0 columns_padding_15 fullwidth-section horizontal-paddings-0 " >
      <!-- <app-cornerfluid></app-cornerfluid> -->
      <div class="tp-bannertimer tp-bottom" style="visibility: hidden !important;"></div>
      <div class="bottom_corner_body"></div>
     </section>

      <app-domiane-intervention id="domaine"></app-domiane-intervention>

      <app-identite id="identite"></app-identite>

      <app-iachievement id="realisation"></app-iachievement>
      <!-- <app-carousel-pause></app-carousel-pause> -->

      <app-technologie id="technologie"></app-technologie>

      <app-actualite id="actualite"></app-actualite>

      <app-opportunite id="opportunite"></app-opportunite>


      <app-projet id="projet"></app-projet>

      <app-buraux id="bureau"></app-buraux>

      <app-contact id="contact"></app-contact> <br> <br><br>

      <app-partenaire id="partenaire"></app-partenaire> <br> <br><br> <br>

      <app-content-footer></app-content-footer>

     <app-copy-right></app-copy-right>
      <!-- <app-carouse-pause></app-carouse-pause>  -->

    </div>
   </div>
  </div>

  <button (click)="goToHome()" mat-mini-fab style="position: fixed; bottom: 20px; z-index: 10; right: 20px; padding-right: 10px;" mat-flat-button color="primary">
    <mat-icon>expand_less</mat-icon>
  </button>

  </body>


