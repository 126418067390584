

<mat-dialog-content>
  <div fxLayout="column" fxLayout.gt-sm="row wrap">
    <button (click)="onNoClick()" color="secondary" style="position: fixed; z-index: 10; top: 45px; left: 10%;" mat-mini-fab>
      <mat-icon style="margin-right:20px">close</mat-icon>
    </button>
    <div fxFlex="100" class="flex-p">
      <mat-card class="custom-card scrollable-content">
          <mat-card-header style="background-color: black; width: 100%; padding: 0px;" class="p-1" fxLayoutAlign="space-between center">
              <mat-card-title class="m-0"><h3 style="color: rgb(255, 255, 255);" class="actTitre">{{data.titre}}</h3></mat-card-title>
          </mat-card-header>
          <mat-card-content class="p-">
            <div class="actuImage" style="background-image: url('{{apiUrl}}image/download/{{data.photo}}');">

            </div>
              <p class="actDescription" [innerHTML]="data.description">

              </p>
          </mat-card-content>


      </mat-card>
    </div>

  </div>

</mat-dialog-content>

<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-label>Commentaire</mat-label>
    <textarea [(ngModel)]="commentaire.commentaire" name="commentaire" matInput placeholder="Commentez ici..."></textarea>
    <button (click)="sendComment()" style=" position: absolute; right: 0px; bottom: 10px;" color="secondary" mat-mini-fab>
      <mat-icon>send</mat-icon>
    </button>
  </mat-form-field>
</form>
