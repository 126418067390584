import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Categorie } from '../model/categorie';
import { Projet } from '../model/projet';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-projet',
  templateUrl: './projet.component.html',
  styleUrls: ['./projet.component.css']
})
export class ProjetComponent implements OnInit {

  projets: Projet[] = [];
  apiUrl = environment.apiUrl;
  categories: Categorie[];
  currentCategorie: Categorie = new Categorie();

  constructor(private service: AllService, private router: Router) { }

  ngOnInit(): void {
    this.getAllProjet();
    this.getAllCategorie();
  }

  getCurrentCategorie(cat: Categorie) {
    this.currentCategorie = cat;
    this.getProjetByCategorie(cat.id);
  }
  getProjetByCategorie(id) {
    this.service.getAllProjetCategorie(id).subscribe((proj) => {
      this.projets = proj;
      console.log("projet", proj);
    });
  }

  getAllProjet() {
    this.currentCategorie = new Categorie();
    this.service.getAllProjet().subscribe((res) => {
      console.log(res);
      this.projets = res;
    });
  }

  goToDetail(proj: Projet) {
    let nav: NavigationExtras = {
      queryParams: {
        projet: JSON.stringify(proj)
      }
    };
    localStorage.setItem('idForScroll', 'projet');
    this.router.navigate(['detail-projet'], nav);
  }

  getAllCategorie() {
    this.service.getAllCategorie().subscribe((cat) => {
      console.log("catogorie", cat);
      this.categories = cat;
    });
  }

  allProjet() {
    this.router.navigate(['all-projet']);
    localStorage.setItem('idForScroll', 'projet');
  }
}
