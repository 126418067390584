<button (click)="back()" mat-mini-fab color="black" style="position: fixed; z-index: 10; left: 10px; top: 10px;">
  <mat-icon style="margin-right: 10px;">keyboard_backspace</mat-icon>
</button>

<!-- <app-header style="position: fixed; z-index: 10; width: 100%;"></app-header> -->

<mat-card class="example-card">
  <mat-card-content>

    <div class="parallax" style="background-image: url('{{apiUrl}}image/download/{{technologie.bgImage}}');">

    </div>
  </mat-card-content>
  <mat-card-title >{{technologie.titre}}</mat-card-title>
  <p [innerHTML]="technologie.description">

  </p> <br>
  <i><span style="font-weight: bold;">Téléchargez {{technologie.titre}} ici: </span> <a href="https://{{technologie.path}}" target="_blank">{{technologie.path}}</a></i>

    <!-- <mat-card-actions style=" margin-left: 5px;">
  </mat-card-actions> -->
</mat-card> <br><br>

<h3>Les <b>captures</b> de <span>{{technologie.titre}}</span> </h3>
<div class="tecno" fxLayout="column" fxLayout.gt-sm="row wrap">
  <div fxFlex="20" class="flex-p" *ngFor="let cap of captures">
    <mat-card class="custom-card">
        <mat-card-header class="bg-warn p-1" fxLayoutAlign="space-between center">
            <mat-card-title class="m-0"><h3>{{cap.titre}}</h3></mat-card-title>
        </mat-card-header>
        <div id="immph" class="bg" style="background-image: url('{{apiUrl}}image/download/{{cap.path}}');">
        </div>
        <mat-card-content class="p-">
            <p [innerHTML]="cap.description">

            </p>
        </mat-card-content>
    </mat-card>
  </div>
</div> <br><br>

<h3>Quelques <b>fonctionnalités</b> de <span>{{technologie.titre}}</span> </h3>
<div class="tecno" fxLayout="column" fxLayout.gt-sm="row wrap">
  <div fxFlex="20" class="flex-p" *ngFor="let fonc of fonctionalites">
    <mat-card class="custom-card">
        <mat-card-header class="bg-warn p-1" fxLayoutAlign="space-between center">
            <mat-card-title class="m-0"><h2>{{fonc.titre}}</h2></mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <!-- <div id="immph" class="bg" style="background-image: url('{{apiUrl}}image/download/{{fonc.path}}');">
        </div> -->
        <mat-card-content class="p-">
            <p [innerHTML]="fonc.description">

            </p>

        </mat-card-content>
    </mat-card>
  </div>
</div> <br><br>

<div class="container-fluid">
	<div class="row text-center">
    <div class="col col-md-3" *ngFor="let res of resultats">
	    <div class="counter">
        <app-animated [digit]="res.chiffre" [duration]="1000"></app-animated>
        <p class="count-text">{{res.titre}}</p>
      </div>
	  </div>
    </div>
</div>

<app-copy-right></app-copy-right>



