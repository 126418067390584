import { Component, OnInit } from '@angular/core';
import { Partenaire } from '../model/partenaire';
import { Techonologie } from '../model/technologie';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-iachievement',
  templateUrl: './iachievement.component.html',
  styleUrls: ['./iachievement.component.css']
})
export class IachievementComponent implements OnInit {

  partenaires: Partenaire[] = [];
  projetTechno: number;
  constructor(private service: AllService) { }


  ngOnInit(): void {
    this.getPartenaire();
    this.getAllTechnologie();
  }

  getPartenaire() {
    this.service.getAllPartenaire().subscribe((res) => {
      console.log(res);
      this.partenaires = res;
    });
  }
  getAllTechnologie() {
    this.service.getAllTechnologie().subscribe((res) => {
      // console.log(res)
      this.service.getAllProjet().subscribe((proj) => {
        this.projetTechno = proj.length + res.length;
      });
    });
  }
}
