<header id="header" class="main-header-wrap">
  <div class="page_header ds toggler_xs_right affix-top with_bottom_border">
   <div class="container-fluid">
    <div class="row">
     <div class="col-sm-12 display_table" >
      <div class="header_left_logo display_table_cell">
       <a  class="logo logo_image_and_text">
        <img src="assets/img/logo-nightmode.png" alt="Tuwindi">
       </a>
      </div>
      <div class="header_mainmenu display_table_cell text-center">
       <nav class="mainmenu_wrapper primary-navigation">
        <ul id="menu-main-menu" class="sf-menu nav-menu nav">
          <li id="menu-item-126" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-126">
         <a href="#top" >Accueil</a>
        </li>
         <li id="menu-item-2765" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-2765">
          <a (click)="goToAbout()" >Qui sommes-nous ?</a>
         </li>
         <!-- <li id="menu-item-6771" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6771">
          <a>Nos initiatives</a>
         </li> -->
         <li id="menu-item-6303" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6303">
          <a href="#projet">Nos projets</a>
         </li>
         <li id="menu-item-6303" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6303">
          <a href="#technologie">Nos technologies</a>
         </li>
         <li id="menu-item-212" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-212">
          <a href="#actualite">Actualit&eacute;s</a>
         </li>
         <!-- <li id="menu-item-212" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-212">
          <a href="#technologie">Nos technologies</a>
         </li> -->
         <li id="menu-item-7988" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-7988">
          <a>Offres</a>
          <ul class="sub-menu">
           <li id="menu-item-7992" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7992">
            <a (click)="goToOffre('recrutements')">Recrutements</a>
           </li>
           <li id="menu-item-7995" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7995">
            <a (click)="goToOffre('appels à projet')">Appels &agrave; projets</a></li>
           <li id="menu-item-7990" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7990">
            <a (click)="goToOffre('concours')">Concours</a>
           </li>
           </ul>
         </li>
         <!--<li id="menu-item-6736" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-6736">
          <a>Notre equipe </a>
         </li>-->
        </ul>
       </nav>
       <span (click)="openNav()" class="toggle_menu"><span></span></span>


       <!--test sidemenu-->
       <div id="mySidenav" class="sidenav">
        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
        <a href="#header" >Accueil</a>
        <a (click)="goToAbout()" >Qui sommes-nous ?</a>
        <a href="#projet">Nos projets</a>
        <a href="#technologie">Nos technologies</a>
        <a href="#actualite">Actualit&eacute;s</a>
        <a href="#contact">Notre contact</a>
        <a (click)="goToOffre('recrutements')">Recrutements</a>
        <a (click)="goToOffre('appels à projet')">Appels &agrave; projets</a>
        <a (click)="goToOffre('concours')">Concours</a>
      </div>

      <!-- <h2>Animated Sidenav Example</h2>
      <p>Click on the element below to open the side navigation menu.</p> -->
      <!-- <span style="font-size:30px;cursor:pointer" (click)="openNav()">&#9776; open</span> -->


      </div>
      <div class="header_right_buttons display_table_cell text-right hidden-xs">
       <span class="header_phone">
                 +223 71 91 91 91
       </span>
      </div><!-- eof .header_button -->
     </div><!--	eof .col-sm-* -->
    </div><!--	eof .row-->
   </div> <!--	eof .container-->
  </div><!-- eof .page_header -->

 </header>
