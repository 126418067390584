import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    // localStorage.removeItem('currentUser');
    this.scroll();
  }

  scroll() {
    if(localStorage.getItem("idForScroll")) {
      let id = localStorage.getItem("idForScroll");
    let el = document.getElementById(id);
    el.scrollIntoView();
    }
  }
goToHome() {
  let el = document.getElementById('top');
  el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  // window.scroll(0,0);
}
allProjet() {
  this.router.navigate(['all-projet']);
  localStorage.setItem('idForScroll', 'header');
}
}
