import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {

    nomUtilisateur;
    authStore = {};
    token = '';
    xhr;
    constructor(public router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {



      if(localStorage.getItem('userToken')) {
        this.token = localStorage.getItem('userToken');
          this.xhr = req.clone({
            headers: req.headers.set('Authorization', `Basic ${this.token}`)
          });
      } else {
        this.xhr = req.clone({
          headers: req.headers.set('X-Requested-With', 'XMLHttpRequest')
        });
      }


      // console.log(this.xhr);


        return next.handle(this.xhr);
    }
}

