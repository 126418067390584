import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  registerUser(user: User): Observable<any> {
    return this.http.post(this.apiUrl + 'users/visiteur', user);
  }
  loginUser(username): Observable<any> {
    return this.http.get(`${this.apiUrl}login`);
  }
  getUserByMail(username: string): Observable<any> {
    return this.http.get(this.apiUrl + 'users/byUsername/' + username);
  }
  getUserByPass(motDePasse: string): Observable<any> {
    return this.http.get(this.apiUrl + 'users/byPass/' + motDePasse);
  }
  updateUser(user: User): Observable<any> {
    return this.http.put(this.apiUrl + 'users/update', user);
  }

}
