
<mat-dialog-content>
  <mat-card>
    <mat-card-content>
      <a style="font-size: 10px;" *ngIf="typePage == 'register'" mat-button (click)="changePage('login')">Avez-vous un compte? Connectez-vous ici!</a>
      <a style="font-size: 10px;" *ngIf="typePage == 'login'" mat-button (click)="changePage('register')">N'avez-vous pas un compte? Inscrivez-vous ici maintenant!</a>

      <form *ngIf="typePage == 'login'" [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
        <h2>Connexion</h2>

        <mat-form-field >
          <input matInput placeholder="Email" formControlName="nomUtilisateur" required>
          <mat-error *ngIf="form.controls.nomUtilisateur.errors?.required">Email est obligatoire</mat-error>
          <mat-error *ngIf="form.controls.nomUtilisateur.hasError('invalidEmail')">Adresse mail invalide</mat-error>
        </mat-form-field>
        <mat-form-field >
          <input matInput  placeholder="Mot de passe" formControlName="motDePasse" required>
          <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon> -->
          <mat-error *ngIf="form.controls.motDePasse.errors?.required">Mot de passe est obligatoire</mat-error>
          <mat-error *ngIf="form.controls.motDePasse.hasError('minlength')">Mot de passe n'est pas long, 6 caractères au minimum</mat-error>
        </mat-form-field>

        <mat-spinner *ngIf="isLoging == true" style="position: absolute; right: 40px; bottom: 20px;" [diameter]="30"></mat-spinner>

        <button mat-raised-button style="background-color: black; color: white;">Se connecter</button>
      </form>


      <!--register-->
      <form *ngIf="typePage == 'register'" [formGroup]="formRegister" (ngSubmit)="register(formRegister.value)">
        <h2>Création de compte</h2>

        <mat-form-field>
          <input matInput placeholder="Prénom" formControlName="prenom">
          <mat-error *ngIf="formRegister.controls.prenom.errors?.required">Prénom est oblogatoire</mat-error>
          <!-- <mat-error *ngIf="form.controls.name.hasError('minlength')">Full Name isn't long enough, minimum of 3 characters</mat-error> -->
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Nom" formControlName="nom">
          <mat-error *ngIf="formRegister.controls.nom.errors?.required">Nom est oblogatoire</mat-error>
          <!-- <mat-error *ngIf="form.controls.name.hasError('minlength')">Full Name isn't long enough, minimum of 3 characters</mat-error> -->
        </mat-form-field>

        <mat-form-field >
          <input matInput placeholder="Email" formControlName="nomUtilisateur" required>
          <mat-error *ngIf="formRegister.controls.nomUtilisateur.errors?.required">Email est obligatoire</mat-error>
          <mat-error *ngIf="formRegister.controls.nomUtilisateur.hasError('invalidEmail')">Adresse mail invalide</mat-error>
        </mat-form-field>
        <mat-form-field >
          <input matInput  placeholder="Mot de passe" formControlName="motDePasse" required>
          <!-- <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon> -->
          <mat-error *ngIf="formRegister.controls.motDePasse.errors?.required">Mot de passe est obligatoire</mat-error>
          <mat-error *ngIf="formRegister.controls.motDePasse.hasError('minlength')">Mot de passe n'est pas long, 6 caractères au minimum</mat-error>
        </mat-form-field>

        <mat-spinner *ngIf="isLoging == true" style="position: absolute; right: 40px; bottom: 20px;" [diameter]="30"></mat-spinner>

        <button mat-raised-button style="background-color: black; color: white;">Créer compte</button>
      </form>

    </mat-card-content>
  </mat-card>
</mat-dialog-content>
