<section id="section-d6087ed" class="fw-main-row section ls section_padding_top_130 section_padding_bottom_30 columns_padding_15" >
  <!-- <h3> Actualit&eacute;s</h3> -->
  <div class="top_corner_body"></div>
  <div class="container">
   <div class="row">
    <div id="column-2b795ef" class="col-xs-12 left fw-column">
     <div class="fw-divider-space hidden-lg hidden-md hidden-sm" style="margin-top: 45px;"></div>
     <div class="special-heading text-center">
      <h2 class="section_header margin_0 big_title ">
       <span class=" medium text-transform-none">Actualit&eacute;s	</span>
      </h2>
     </div>
     <div class="fw-divider-space " style="margin-top: 45px;"></div>
     <div class="shortcode-posts tiled-layout c-gutter-30 c-mb-30">

      <div (click)="goToDetail(alaUne)" style="cursor: pointer;" class="bigitem col-lg-6 col-md-12 col-sm-12 col-xs-12">
       <div class="vertical-item content-padding big-padding with_shadow overflow-hidden post-6724 post type-post status-publish format-image has-post-thumbnail hentry category-web-design-blog-fullwidth tag-design tag-web-design post_format-post-format-image">
        <div class="item-media-wrap">
         <div style="height: 300px;" class="item-media">
          <img src="{{apiUrl}}image/download/{{alaUne.photo}}" class="attachment-deepdigital-rectangular size-deepdigital-rectangular wp-post-image" alt="" loading="lazy" />        <div class="media-links">
          <a class="abs-link"></a>
         </div>
         </div>
        </div>
        <div class="item-content">
         <h4 class="item-title">
          <a> {{alaUne.titre}} </a>
         </h4>
         <p [innerHTML]="alaUne.description">

         </p>
         <div class="small-text post-date">
          <span class="post-date">
           <a rel="bookmark">
            <time datetime="2018-06-17T15:14:50+00:00">{{alaUne.dateCreation | date}}</time>
           </a>
          </span>
         </div>
        </div>
       </div>
      </div>


      <div class="smallitem col-lg-6 col-md-12 col-sm-12 col-xs-12">
        <div (click)="goToDetail(actu)" *ngFor="let actu of actualites | slice:0:2" class="side-item text-center post-6721 post type-post status-publish format-video has-post-thumbnail hentry category-logo-design-blog-fullwidth tag-design post_format-post-format-video">
        <div class="item-media-wrap">
         <div class="item-media">
          <img  width="600" height="600" src="{{apiUrl}}image/download/{{actu.photo}}" class="attachment-deepdigital-square size-deepdigital-square wp-post-image" alt="" loading="lazy"/>
          <div class="media-links">
           <a class="abs-link"></a>
          </div>
         </div>
        </div>
        <div class="item-content">
         <h6 class="item-title">
          <a> {{actu.titre}} </a>
         </h6>
         <p [innerHTML]="actu.description"></p>

         <div class="small-text post-date">
          <span class="post-date">
           <a rel="bookmark"><time datetime="2018-06-17T15:13:56+00:00">{{actu.dateCreation | date}}</time></a></span>
         </div>
        </div>
       </div>
       <a (click)="allActu()" style="cursor: pointer; margin-top: 15px;" class="wide_button theme_button inverse color1 medium_height">Toutes les actualités</a>

      </div>


     </div><!-- eof .istotpe-wrapper -->
     <div class="fw-divider-space hidden-lg hidden-md hidden-xs" style="margin-top: 50px;"></div>
     <div class="fw-divider-space hidden-sm hidden-xs" style="margin-top: 100px;"></div>
    </div>
   </div>
  </div>
  <div class="bottom_corner_body"></div>
 </section>
