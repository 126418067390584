<section id="section-7fc8a31" class="fw-main-row section ds section_padding_top_85 section_padding_bottom_85 columns_padding_15 table_section table_section_md" style="background-image:url(assets/img/2019/05/advantages.jpg);">

  <div class="top_corner_body"></div>
  <div class="container">
    <h1> Technologies phares</h1>
   <div class="row">
    <div id="column-d49de59" class="col-xs-12 col-md-4 left fw-column">

       <div (click)="goToDetail(tec)" *ngFor="let tec of technologies1">
        <div id="box-7f51324" class="icon-box icon_left mobile-center">
          <div class="box_icon color-main">
           <img src="{{apiUrl}}image/download/{{tec.bgImage}}" alt="7414">
          </div>
          <div class="box-wrap">
           <h5 class="box-heading">
            <a>
             {{tec.titre}}
            </a>
           </h5>
           <div [innerHTML]="tec.description" class="box-content">

            </div>
          </div>
         </div>
         <div class="fw-divider-space " style="margin-top: 50px;"></div>
       </div>

    </div>

    <div id="column-8698aa7" class="col-xs-12 col-md-4 text-center left fw-column" >
     <div class="fw-divider-space " style="margin-top: 50px;"></div>
     <div class="img-wrap">
      <img id="immph" src="assets/img/2019/05/phone.png" alt="assets/img/2019/05/phone.png" />
     </div>
     <div class="fw-divider-space " style="margin-top: 50px;"></div>
    </div>

    <div id="column-a13486d" class="col-xs-12 col-md-4 left fw-column">
      <div (click)="goToDetail(tec)" *ngFor="let tec of technologies2">
        <div id="box-7f51324" class="icon-box icon_left mobile-center">
          <div class="box_icon color-main">
           <img src="{{apiUrl}}image/download/{{tec.bgImage}}" alt="7414">
          </div>
          <div class="box-wrap">
           <h5 class="box-heading">
            <a>
             {{tec.titre}}
            </a>
           </h5>
           <div [innerHTML]="tec.description" class="box-content">

            </div>
          </div>
         </div>
         <div class="fw-divider-space " style="margin-top: 50px;"></div>
       </div>

     <!-- <div id="box-91e10be" class="icon-box icon_right mobile-center">
      <div class="box_icon color-main">
       <img src="assets/img/2019/05/icon_alt4.png" alt="7417">
      </div>
      <div class="box-wrap">
       <h5 class="box-heading">
        <a> Wuya </a>
       </h5>
       <div class="box-content"> Laboris nisi ut aliquip ex ea commodo consequat aute </div>
      </div>
     </div>

     <div class="fw-divider-space " style="margin-top: 50px;"></div>

     <div id="box-d4b7448" class="icon-box icon_right mobile-center">
      <div class="box_icon color-main">
       <img src="assets/img/2019/05/icon_alt5.png" alt="7418">
      </div>
      <div class="box-wrap">
       <h5 class="box-heading">
        <a> Rem </a>
       </h5>
       <div class="box-content"> Dolor reprehenderit in voluptate velit esse cillum dolore eu fugiat </div>
      </div>
     </div>

     <div class="fw-divider-space " style="margin-top: 50px;"></div>

     <div id="box-ffde9d0" class="icon-box icon_right mobile-center">
      <div class="box_icon color-main">
       <img src="assets/img/2019/05/icon_alt6.png" alt="7419">
      </div>
      <div class="box-wrap">
       <h5 class="box-heading">
        <a> ASSA </a>
       </h5>
       <div class="box-content">Excepteur sint occaecat cupidatat non proident, sun </div>
      </div>
     </div> -->

    </div>
   </div>
  </div>
  <div class="bottom_corner_body"></div>
 </section>
