<section id="section-2bf0135" class="fw-main-row section ds section_padding_top_150 section_padding_bottom_100 columns_padding_15 parallax background_cover " >
  <h3 class="hidden"> Bureaux </h3>
  <div class="top_corner_body"></div>
  <div class="container">
  <div class="row">
  <div id="column-ecce40b" class="col-xs-12 col-md-6 left fw-column">
  <div class="special-heading text-left">
  <h2 class="section_header margin_0 big_title ">
  <span class="lightfont medium text-transform-none"> Nos bureaux	</span>
  </h2>
  </div>
  <div class="fw-divider-space " style="margin-top: 42px;"></div>
  <div class="text-block shortcode lightfont">
  <p>Lorem ipsum dolor amet onsectetur<br />adipisicing eiusmod.</p>
  </div>
  <div class="fw-divider-space " style="margin-top: 40px;"></div>
  <div class="bootstrap-tabs tabs-icons color_1">
  <ul class="nav nav-tabs" role="tablist">
  <li class="active">
  <a href="#tab-a35b8a3713520e185ceb7de6d8f62af4-0" role="tab" data-toggle="tab"> Mali </a>
  </li>
  <li class="">
  <a href="#tab-a35b8a3713520e185ceb7de6d8f62af4-1" role="tab" data-toggle="tab"> Niger </a>
  </li>
  <li class="">
  <a href="#tab-a35b8a3713520e185ceb7de6d8f62af4-2" role="tab" data-toggle="tab"> Burkina Faso </a>
  </li>
  </ul>
  <div class="tab-content">
  <div class="tab-pane fade in active" id="tab-a35b8a3713520e185ceb7de6d8f62af4-0">
  <div class="icons-list">
  <ul class="no-bullets">
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap">
  <i class="fa fa-map-marker"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text"> Baco Djicoroni, Bamako - Mali </span>
  </div>
  </div>
  </li>
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap">
  <i class="fa fa-phone"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text"> 223 71 91 91 91 </span>
  </div>
  </div>
  </li>
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap">
  <i class="fa fa-envelope"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text"> <a href="mailto:kibaru@tuwindi.org">kibaru@tuwindi.org</a>
  </span>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div><!-- .eof tab-pane -->
  <div class="tab-pane fade" id="tab-a35b8a3713520e185ceb7de6d8f62af4-1">
  <div class="icons-list">
  <ul class="no-bullets">
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap"> <i class="fa fa-map-marker"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text"> Baco Djicoroni, Bamako - Mali </span>
  </div>
  </div>
  </li>
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap"> <i class="fa fa-phone"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text"> 223 71 91 91 91 </span>
  </div>
  </div>
  </li>
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap">
  <i class="fa fa-envelope"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text">
  <a href="mailto:kibaru@tuwindi.org">kibaru@tuwindi.org</a>
  </span>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div><!-- .eof tab-pane -->
  <div class="tab-pane fade" id="tab-a35b8a3713520e185ceb7de6d8f62af4-2">
  <div class="icons-list">
  <ul class="no-bullets">
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap">
  <i class="fa fa-map-marker"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text"> Baco Djicoroni, Bamako - Mali </span>
  </div>
  </div>
  </li>
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap">
  <i class="fa fa-phone"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text"> 223 71 91 91 91 </span>
  </div>
  </div>
  </li>
  <li>
  <div class="media small-teaser inline-block">
  <div class="media-left">
  <div class="icon-wrap">
  <i class="fa fa-envelope"></i>
  </div>
  </div>
  <div class="media-body">
  <span class="text">
  <a href="mailto:kibaru@tuwindi.org">kibaru@tuwindi.org</a>
  </span>
  </div>
  </div>
  </li>
  </ul>
  </div>
  </div><!-- .eof tab-pane -->
  </div>
  </div>
  <div class="fw-divider-space " style="margin-top: 40px;"></div>
  </div>
  <div id="column-a653909" class="col-xs-12 col-md-6 left fw-column">
  <div class="text-block shortcode ">
  <div data-plugin="responsive-vector-maps" data-ver="6.3.8" class="map-container rvm-map-container" id="world-map-7468" style="width:100%;"></div>
  </div>
  </div>
  </div>
  </div>
  <div class="bottom_corner_body"></div>
  </section>
