

<div fxLayout="column">
  <div class="flex-p">

      <mat-card>
        <button (click)="back()" mat-mini-fab color="black">
          <mat-icon style="margin-right: 10px;">keyboard_backspace</mat-icon>
        </button>
          <!-- <mat-card-header fxLayoutAlign="center center">
              <mat-card-subtitle><h2>Basic tabs</h2></mat-card-subtitle>
          </mat-card-header> -->
          <mat-card-content>
              <mat-tab-group (selectedTabChange)="getTabsValue($event)">
                  <mat-tab label="projet">
                    <section id="section-82bed5f" class="fw-main-row section ls section_padding_top_130 section_padding_bottom_130 columns_padding_15">
                      <h3 class="hidden"> Nos Projets</h3>
                      <div class="top_corner_body"></div>
                      <div class="container">
                      <div class="row">
                      <div id="column-be2a1ba" class="col-xs-12 text-center left fw-column">
                      <div class="fw-divider-space " style="margin-top: 15px;"></div>
                      <div class="special-heading text-center">
                      <h2 class="section_header margin_0 big_title">
                      <span class=" medium text-transform-none"> Nos projets	</span>
                      </h2>
                      </div>
                      <div class="fw-divider-space " style="margin-top: 44px;"></div>
                      <div class="filters isotope_filters-60269bd4a7785 text-center">
                        <button (click)="getAllProjet()" [color]="!currentCategorie.id ? 'primary' : 'secondary'" mat-stroked-button color="primary">Tout</button>
                        <button (click)="getCurrentCategorie(cat, 'projet')" *ngFor="let cat of categories" mat-stroked-button [color]="currentCategorie.id == cat.id ? 'primary' : 'secondary'">{{cat.nom}}</button>
                      </div><!-- eof isotope_filters -->
                      <div class="columns_padding_15">
                      <div class="isotope_container isotope row masonry-layout" data-filters=".isotope_filters-60269bd4a7785">

                      <div (click)="goToDetail(pro)" *ngFor="let pro of projets" class="isotope-item item-layout-item-regular-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 web-design-shortcode ">
                      <div class="vertical-item gallery-item content-absolute text-left bottommargin_10 ds">
                      <div style="background-image: url('{{apiUrl}}image/download/{{pro.path}}');" class="bg item-media">
                        <div class="proImage">
                          <img width="1170" height="780" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy" sizes="(max-width: 1170px) 100vw, 1170px" />

                        </div>
                      <div class="media-links">
                      <div class="media-wrap">
                      <div class="categories-links">
                      <!--href="../portfolio/web-design-shortcode/index.html"-->
                      <a rel="tag">{{pro.categorie.nom}}</a>
                       </div>
                      <div class="content-wrap">
                      <h6 class="item-title">
                      <a >
                      {{pro.nom}}
                      </a>
                      </h6>
                      <div class="small-text post-date">
                      <span class="post-date"><a  rel="bookmark"><time datetime="2018-04-11T12:17:49+00:00">{{pro.dateCreation | date}}</time></a></span>                        </div>
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>

                      </div><!-- eof .isotope_container -->
                      </div><!-- eof .columns_padding_* -->
                      <div class="fw-divider-space " style="margin-top: 40px;"></div>
                      <!-- <a (click)="allProjet()" style="cursor: pointer;" class="wide_button theme_button inverse color1 medium_height">Tous les projets</a> -->
                      <div class="fw-divider-space " style="margin-top: 15px;"></div>
                      </div>
                      </div>
                      </div>
                      <div class="bottom_corner_body"></div>
                      </section>

                    <!-- <p class="py-1">

                  </p> -->
                  </mat-tab>





                  <mat-tab label="initiative">
                    <section id="section-82bed5f" class="fw-main-row section ls section_padding_top_130 section_padding_bottom_130 columns_padding_15">
                      <h3 class="hidden"> Nos Initiatives</h3>
                      <div class="top_corner_body"></div>
                      <div class="container">
                      <div class="row">
                      <div id="column-be2a1ba" class="col-xs-12 text-center left fw-column">
                      <div class="fw-divider-space " style="margin-top: 15px;"></div>
                      <div class="special-heading text-center">
                      <h2 class="section_header margin_0 big_title">
                      <span class=" medium text-transform-none"> Nos Initiatives	</span>
                      </h2>
                      </div>
                      <div class="fw-divider-space " style="margin-top: 44px;"></div>
                      <div class="filters isotope_filters-60269bd4a7785 text-center">
                        <button (click)="getAllInitiative()" [color]="!currentCategorie.id ? 'primary' : 'secondary'" mat-stroked-button color="primary">Tout</button>
                        <button (click)="getCurrentCategorie(cat, 'initiative')" *ngFor="let cat of categories" mat-stroked-button [color]="currentCategorie.id == cat.id ? 'primary' : 'secondary'">{{cat.nom}}</button>
                      </div><!-- eof isotope_filters -->
                      <div class="columns_padding_15">
                      <div class="isotope_container isotope row masonry-layout" data-filters=".isotope_filters-60269bd4a7785">

                      <div (click)="goToDetail(pro)" *ngFor="let pro of projets" class="isotope-item item-layout-item-regular-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 web-design-shortcode ">
                      <div class="vertical-item gallery-item content-absolute text-left bottommargin_10 ds">
                      <div style="background-image: url('{{apiUrl}}image/download/{{pro.path}}');" class="bg item-media">
                        <div class="proImage">
                          <img width="1170" height="780" class="attachment-post-thumbnail size-post-thumbnail wp-post-image" alt="" loading="lazy"  sizes="(max-width: 1170px) 100vw, 1170px" />

                        </div>
                      <div class="media-links">
                      <div class="media-wrap">
                      <div class="categories-links">
                      <!--href="../portfolio/web-design-shortcode/index.html"-->
                      <a rel="tag">{{pro.categorie.nom}}</a>
                       </div>
                      <div class="content-wrap">
                      <h6 class="item-title">
                      <a >
                      {{pro.nom}}
                      </a>
                      </h6>
                      <div class="small-text post-date">
                      <span class="post-date"><a  rel="bookmark"><time datetime="2018-04-11T12:17:49+00:00">{{pro.dateCreation | date}}</time></a></span>                        </div>
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>
                      </div>

                      </div><!-- eof .isotope_container -->
                      </div><!-- eof .columns_padding_* -->
                      <div class="fw-divider-space " style="margin-top: 40px;"></div>
                      <!-- <a (click)="allProjet()" style="cursor: pointer;" class="wide_button theme_button inverse color1 medium_height">Tous les projets</a> -->
                      <div class="fw-divider-space " style="margin-top: 15px;"></div>
                      </div>
                      </div>
                      </div>
                      <div class="bottom_corner_body"></div>
                      </section>

                </mat-tab>
              </mat-tab-group>
          </mat-card-content>
      </mat-card>
  </div>

</div> <br><br>


<app-copy-right></app-copy-right>



