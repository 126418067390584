import { User } from "./user";

export class Contact {
  id?: number;
  nom: string;
  mail: string;
  message: string;
  telephone: number;
  structure: string;
  dateCreation: Date;
  dateModification: Date;
  supprime: boolean;
  objet: string;
  // user: User
}
