import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Article } from '../model/article';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-actualite',
  templateUrl: './actualite.component.html',
  styleUrls: ['./actualite.component.css']
})
export class ActualiteComponent implements OnInit {
  actualites: Article[] = [];
  apiUrl = environment.apiUrl;
  alaUne: Article = new Article();
  constructor(private service: AllService,
    private router: Router) { }

  ngOnInit(): void {
    this.getAllActualite();
    this.getAlaUne();
  }

  getAllActualite() {
    this.service.getAllArticleWithoutAlaUne().subscribe((actu) => {
      this.actualites = actu;
      console.log(actu);
    });
  }

  getAlaUne() {
    this.service.getAlaUneArticle().subscribe((res) => {
      console.log('a la une', res);
      this.alaUne = res;
    });
  }

  goToDetail(actu) {
    let nav: NavigationExtras = {
      queryParams: {
        actu: JSON.stringify(actu)
      }
    };
    localStorage.setItem('idForScroll', 'actualite');
    this.router.navigate(['detail-actu'], nav);
  }

  allActu() {
    this.router.navigate(['all-actualite']);
    localStorage.setItem('idForScroll', 'actualite');
  }
}
