<button (click)="back()" mat-mini-fab color="black" style="position: fixed; z-index: 10; left: 10px; top: 10px;">
  <mat-icon style="margin-right: 10px;">keyboard_backspace</mat-icon>
</button> <br>
<!-- <mat-divider></mat-divider> -->

<div style="padding:40px;">
  <div class="titre" style="text-align: center;">
    <span style="font-size: 50px;">A propos de nous</span>
  </div>
  <mat-divider></mat-divider>
  <div class="row">
    <div class="col col-md-4">
      <mat-card class="custom">
        <mat-card-content>
          <div style="width: 100%;">
            <img src="http://tuwindi.io/wp-content/uploads/2019/12/opportinuit%C3%A9.jpg" alt="">
          </div>
          <!-- <p>
            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from
            Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu
            was originally bred for hunting.
          </p> -->
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col col-md-8">
      Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.

      Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio molestiae sit voluptates assumenda omnis dolorem porro non placeat natus iusto modi, reprehenderit voluptatum ducimus ab unde ad quis amet soluta. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.
    </div>
  </div>

</div> <br>

<app-copy-right></app-copy-right>


