<h2 class="section_header margin_0 big_title">
  <div style="text-align: center;">
    <span class=" medium text-transform-none"> Nos valereux partenaires	</span>
  </div>
  </h2> <br> <br>

  <!-- <mat-divider></mat-divider> -->
  <owl-carousel-o [options]="customOptions">

    <ng-container *ngFor="let part of partenaires">
      <ng-template carouselSlide [id]="part.id">
        <div class="imCarousel">
          <img src="{{apiUrl}}image/download/{{part.logo}}">
        </div>
      </ng-template>
    </ng-container>

    </owl-carousel-o>

<!-- <div class="row">
  <div class="col col-md-2 col-xs-12" *ngFor="let part of partenaires">
   <mat-card>
    <div>
      <div style="height: 150px; width: 150px;">
        <img style="cursor: pointer;" width="600" height="600" src="{{apiUrl}}image/download/{{part.logo}}" class="attachment-deepdigital-square size-deepdigital-square" alt="" loading="lazy"  sizes="(max-width: 600px) 100vw, 600px" />
       </div>
       <div style="text-align: center; margin-top: 15px;">
        <h2>{{part.nom}}</h2>
       </div>
    </div>
   </mat-card>
   </div>
</div> -->

