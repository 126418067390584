<button (click)="back()" mat-mini-fab color="black" style="position: fixed; z-index: 10; left: 10px; top: 10px;">
  <mat-icon style="margin-right: 10px;">keyboard_backspace</mat-icon>
</button>

<br>
<div style="text-align: center;">
  <h2 class="section_header margin_0 big_title">
    <span class=" medium text-transform-none"> Nos actualités	</span>
    </h2>
</div> <br><br>

<!-- <mat-divider></mat-divider> -->
<div fxLayout="column" fxLayout.gt-sm="row wrap">

  <div (click)="goToDetail(act)" fxFlex="32.5" class="flex-p" *ngFor="let act of actualites">
    <mat-card class="custom-card">
      <mat-card-header style="background-color: black; width: 100%; padding: 0px;" class="p-1" fxLayoutAlign="space-between center">
        <mat-card-title class="m-0"><h3 style="color: rgb(255, 255, 255);" class="actTitre">{{act.titre}}</h3></mat-card-title>
    </mat-card-header>
        <!-- <img mat-card-image src="{{apiUrl}}image/download/{{act.photo}}" alt="5"> -->
        <mat-card-content class="p-">
          <div class="actuImage" style="background-image: url('{{apiUrl}}image/download/{{act.photo}}');">

          </div>

            <p class="actDescription" [innerHTML]="act.description">

            </p>
        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions fxLayoutAlign="end center">
            <button mat-icon-button><mat-icon>insert_comment</mat-icon></button>
            <button mat-icon-button><mat-icon>favorite</mat-icon></button>
            <button mat-icon-button><mat-icon>share</mat-icon></button>
        </mat-card-actions>
    </mat-card>
  </div>

</div> <br><br>

<app-copy-right></app-copy-right>
