import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { CommentPopComponent } from '../comment-pop/comment-pop.component';
import { emailValidator } from '../services/app-validators';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public form:FormGroup;
  public formRegister:FormGroup;
  hide:boolean = true;
  isLoging: boolean = false;
  typePage: string = 'login';
  toastOption: Partial<IndividualConfig> = {
    positionClass: 'middle',
    tapToDismiss: true,
    progressBar: true,
    closeButton: true,
    extendedTimeOut: 5000
  }

  constructor(
    public fb: FormBuilder,
    public router:Router,
    private userService: UserService,
    public dialogRef: MatDialogRef<LoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private toastr: ToastrService,){

    this.form = this.fb.group({
      'nomUtilisateur': [null, Validators.compose([Validators.required, emailValidator])],
      'motDePasse': [null, Validators.compose([Validators.required, Validators.minLength(6)])]
    });
    this.formRegister = this.fb.group({
      'nomUtilisateur': [null, Validators.compose([Validators.required, emailValidator])],
      'motDePasse': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'prenom': ['', Validators.required],
      'nom': ['', Validators.required]
    });
  }
  ngOnInit(): void {
    // localStorage.removeItem('token')
  }

  changePage(typePage) {
    this.typePage = typePage;
  }

  public onSubmit(values:Object):void {
    if (this.form.valid) {
      console.log(this.form.value);
      this.isLoging = true;
      const token = btoa(this.form.value.nomUtilisateur + ':' + this.form.value.motDePasse);
      setTimeout(() => {
        localStorage.setItem('userToken', token);
        this.userService.loginUser(this.form.value.nomUtilisateur).subscribe((res) => {
          if(res) {
            this.isLoging = false;
            localStorage.setItem('currentUser', JSON.stringify(res));
            console.log('currentUser', res);
             this.openDialog(CommentPopComponent);
             this.dialogRef.close();
          } else {
            console.log("fail to login");
            console.log(res);

            this.isLoging = false;
          }
        }, (err) => {
          this.isLoging = false;
          console.log("fail to login");
          console.log(err);

        });
      }, 500);

    } else {
      console.log(this.formRegister);

    }
  }


  public register(values:Object):void {
    localStorage.removeItem('token');
   localStorage.removeItem('currentUser');
   if (this.formRegister.valid) {

     this.userService.getUserByMail(this.formRegister.value.nomUtilisateur).subscribe((user) => {
       if(!user) {
         console.log(this.formRegister.value);
         this.isLoging = true;
         const token = btoa(this.formRegister.value.nomUtilisateur + ':' + this.formRegister.value.motDePasse);
         localStorage.setItem('userToken', token);
         this.userService.registerUser({
           prenom: this.formRegister.value.prenom,
           nom: this.formRegister.value.nom,
           motDePasse: this.formRegister.value.motDePasse,
           nomUtilisateur: this.formRegister.value.nomUtilisateur,
           supprime: false,
           block: false
         }).subscribe((res) => {
         if(res) {
           localStorage.setItem('currentUser', JSON.stringify(res));
           this.isLoging = false;
           console.log("success", res);
           this.openDialog(CommentPopComponent);
           this.dialogRef.close();
           this.toastr.success("Votre inscription a été prise en charge, nous avons envoyé votre mot de passe mail", 'Insciption', this.toastOption);
         } else {
           this.isLoging = false;
           console.log("sign fail");

         }
           // this.initForm();
         }, (err) => {
           this.isLoging = false;
           console.log("err", err);
         });

       } else {
         this.isLoging = false;
         this.toastr.info("Cette adresse mail est déjà utilisée !", "Attention");
       }
     });




     // this.router.navigate(['/login']);
   }
 }



    openDialog(page): void {
    let dialogRef = this.dialog.open(page, {
      data: this.data,
      panelClass: 'my-class'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }


}
