import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-domiane-intervention',
  templateUrl: './domiane-intervention.component.html',
  styleUrls: ['./domiane-intervention.component.css']
})
export class DomianeInterventionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
