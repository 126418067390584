import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Categorie } from '../model/categorie';
import { Projet } from '../model/projet';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-all-projet',
  templateUrl: './all-projet.component.html',
  styleUrls: ['./all-projet.component.css']
})
export class AllProjetComponent implements OnInit {

  projets: Projet[] = [];
  apiUrl = environment.apiUrl;
  currentCategorie: Categorie = new Categorie();
  categories: Categorie[];

  constructor(private service: AllService,
    private router: Router,
    private location: Location) { }

    ngOnInit(): void {
      this.getAllProjet();
      this.getAllCategorie();
    }

    getAllCategorie() {
      this.service.getAllCategorie().subscribe((cat) => {
        console.log("catogorie", cat);
        this.categories = cat;
      });
    }

    getCurrentCategorie(cat: Categorie, type) {
      this.currentCategorie = cat;
      this.getProjetByCategorie(cat.id, type);
    }
    getProjetByCategorie(id, type) {
      this.service.getAllProjetCategorieAndType(id, type).subscribe((proj) => {
        this.projets = proj;
        console.log("projet", proj);
      });
    }

  getAllProjet() {
    this.currentCategorie = new Categorie();
    this.service.getAllInitiative('projet').subscribe((res) => {
      console.log(res);
      this.projets = res;
      window.scroll(0,0);
    });
  }
  getAllInitiative() {
    this.currentCategorie = new Categorie();
    this.service.getAllInitiative('initiative').subscribe((res) => {
      console.log(res);
      this.projets = res;
      window.scroll(0,0);
    });
  }

  goToDetail(proj: Projet) {
    let nav: NavigationExtras = {
      queryParams: {
        projet: JSON.stringify(proj)
      }
    };
    localStorage.setItem('idForScroll', 'projet');
    this.router.navigate(['detail-projet'], nav);
  }

  getTabsValue(ev) {
    console.log(ev.tab.textLabel);
    this.service.getAllInitiative(ev.tab.textLabel).subscribe((res) => {
      this.projets = res;
      // console.log(ev.tab.textLabel, res);
    });
  }

  back() {
    this.location.back();
  }
}
