import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToOffre(type: string) {
    this.router.navigate(['offre', type]);
     localStorage.setItem('idForScroll', 'header');
  }
  goToAbout() {
    this.router.navigate(['propos']);
  }

   openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

   closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
}
