
   <section id="section-e47e34a" class="fw-main-row section ls section_padding_top_150 section_padding_bottom_130 columns_padding_15 section_overlay fullwidth-section background_cover mobile-overlay" style="background-image:url(assets/img/2019/05/img_27-1.jpg);">
    <h3 class="hidden"> Notre identit&eacute; </h3>
    <div class="top_corner_body"></div>
    <div class="container-fluid">
     <div class="row">
      <div id="column-3836bc3" class="col-xs-12 left col-lg-offset-1 col-lg-5 fw-column">
       <div class="special-heading text-left">
        <h2 class="section_header margin_0 big_title ">
         <span class=" medium text-transform-none"> Notre identit&eacute;	</span>
        </h2>
       </div>
       <div class="fw-divider-space " style="margin-top: 50px;"></div>
       <div class="special-heading text-left">
        <p class="big paragraph">
         <span class="darkgrey light-weight text-transform-none">
          NOTRE MISSION EST DE PROPULSER LE DÉVELOPPEMENT ECONOMIQUE ET SOCIAL PAR L’UTILISATION INNOVANTE DES TECHNOLOGIES DE LA COMMUNICATION ET DE L’INFORMATION TIC
         </span>
        </p>
       </div>
       <div class="fw-divider-space" style="margin-top: 20px;"></div>
       <div class="text-block shortcode">
        <p>
         Nous sommes une organisation de droit Malien non partisan à but non lucratif. Notre objectif est d’utiliser les technologies de l’information et de la communication pour soutenir le développement social et économique.
        </p>
       </div>
       <div class="fw-divider-space" style="margin-top: 38px;"></div>
       <div class="special-heading text-left">
        <p class=" paragraph">
         <span class=" light-weight text-transform-none">
          <blockquote>
           <span class="highlight"> Nous sommes une équipe intègre, passionnée, créative et innovante. </span>
          </blockquote>
         </span>
        </p>
       </div>
       <div class="fw-divider-space " style="margin-top: 15px;"></div>
       <div class="special-heading text-left">
        <h5 class="section_header margin_0">
         <span class="darkgrey medium text-transform-none">
          +223-71-91-91-91 <span class="highlight3">&amp;</span> +223-61-91-91-91
         </span>
        </h5>
       </div>
       <div class="text-block shortcode mailchimp">
        <p></p>
        <form id="mc4wp-form-1" class="mc4wp-form mc4wp-form-445" method="post" data-id="445" data-name="Form">
         <div class="mc4wp-form-fields">
          <p class="text_for_footer">Enter your email address here always to be updated. We promise not to spam!</p>
          <p class="mc4wp-form-inner">
           <label>Email Address </label>
           <input class="form-control text-center" type="email" name="EMAIL" placeholder="Email Address" required />
           <button type="submit" class="theme_button" value="Sign up"></button>
          </p>
         </div>
         <label style="display: none !important;">Leave this field empty if you're human:
          <input type="text" name="_mc4wp_honeypot" value="" tabindex="-1" autocomplete="off" />
         </label>
         <input type="hidden" name="_mc4wp_timestamp" value="1613142996" />
         <input type="hidden" name="_mc4wp_form_id" value="445" />
         <input type="hidden" name="_mc4wp_form_element_id" value="mc4wp-form-1" />
         <div class="mc4wp-response"></div>
        </form>
       </div>
       <div class="fw-divider-space " style="margin-top: 35px;"></div>
       <!-- <div class="fw-theme-signature style2">
        <div class="fw-signature-left-part">
         <h4 class="section_header">Eunice D. Matherne</h4>
         <div class="small-text highlight subtitle">Cofounder</div>
        </div>
        <div class="fw-signature-right-part">
         <img src="assets/img/2019/05/signature_4.png" alt="Eunice D. Matherne">
        </div>
       </div> -->
      </div>
     </div>
    </div>
    <div class="bottom_corner_body"></div>
   </section>
