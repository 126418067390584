import { User } from "./user";

export class Categorie {
    id?: number;
    nom: string;
    type: string;
    user: User;
    dateCreation: Date;
    dateModification: Date;
    supprime: boolean;
}