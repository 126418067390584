<section id="section-b0b0780" class="fw-main-row section ls section_padding_top_150 section_padding_bottom_100 columns_padding_25  half_section" >
  <h3 class="hidden"> Nos R&eacute;alisations</h3>
  <div class="top_corner_body"></div>
  <!--<div class="cover_image" style="background-image:url('assets/img/2019/05/home2_alt_half_media.jpg')">-->
  <div class="image_cover image_cover_left" style="background-image:url('assets/img/2019/05/home2_alt_half_media.jpg')"></div>
  <div class="container">
   <div class="row">
    <div id="column-ff76dec" class="col-xs-12 col-md-6 left fw-column"></div>
    <div id="column-804589a" class="col-xs-12 col-md-6 left col-lg-5 col-lg-offset-1 fw-column">
     <div class="special-heading text-left">
      <h2 class="section_header margin_0 big_title xs_middle ">
       <span class=" medium text-transform-none"> Nos réalisations	</span>
      </h2>
     </div>
     <div class="fw-divider-space hidden-xs" style="margin-top: 50px;"></div>
     <div class="fw-divider-space hidden-lg hidden-md hidden-sm" style="margin-top: 25px;"></div>
     <div class="text-block shortcode ">
      <p>Duis aute irure dolor reprehenderit in voluptate velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat cupidatat non proident unt in culpa qui officia deserunt mollit anim.</p>
     </div>
     <div class="fw-divider-space " style="margin-top: 50px;"></div>
     <div class="shortcode-simple-counter col-md-12">
      <div id="counter-60269bd4a0684" class="counter_wrap col-md-6">
       <div class="counter_icon color-main">
        <img src="assets/img/2019/05/counter_img1.png" alt="7394">
       </div>
       <h2 class="counter counter-size" data-from="0" data-to="1354" data-speed="1000">{{projetTechno}}</h2>
       <h6 class="counter-text">
        <span class="counter-add">Projets & Technologies</span>
       </h6>
      </div>
      <div id="counter-60269bd4a0784" class="counter_wrap col-md-6">
       <div class="counter_icon color-main">
        <img src="assets/img/2019/05/counter_img3.png" alt="7401">
       </div>
       <h2 class="counter counter-size" data-from="0" data-to="37" data-speed="1000">3</h2>
       <h6 class="counter-text">
        <span class="counter-add">Pays d&apos;interventions</span>
       </h6>
      </div>
      <div id="counter-60269bd4a07fd" class="counter_wrap col-md-6">
       <div class="counter_icon color-main">
        <img src="assets/img/2019/05/counter_img4.png" alt="7402">
       </div>
       <h2 class="counter counter-size" data-from="0" data-to="24" data-speed="1000">3</h2>
       <h6 class="counter-text">
        <span class="counter-add">Bureaux pays</span>
       </h6>
      </div>
      <div class="counter_wrap col-md-6">
       <div class="counter_icon color-main">
        <img src="assets/img/2019/05/counter_img4.png" alt="7402">
       </div>
       <h2 class="counter counter-size" data-from="0" data-to="24" data-speed="1000">1250</h2>
       <h6 class="counter-text">
        <span class="counter-add">Jeunes & Femmes mobilis&eacute;s</span>
       </h6>
      </div>
      <div id="counter-60269bd4a0709" class="counter_wrap">
       <div class="counter_icon color-main">
        <img src="assets/img/2019/05/counter_img4.png" alt="7402">
       </div>
       <h2 class="counter counter-size" data-from="0" data-to="24" data-speed="1000">{{partenaires.length}}</h2>
       <h6 class="counter-text">
        <span class="counter-add">Partenaires</span>
       </h6>
      </div>
      <div class="counter_wrap col-md-6">
       <div class="counter_icon color-main">
        <img src="assets/img/2019/05/counter_img4.png" alt="7402">
       </div>
       <h2 class="counter counter-size" data-from="0" data-to="24" data-speed="1000">5</h2>
       <h6 class="counter-text">
        <span class="counter-add">Ann&eacute;es d&apos;experience</span>
       </h6>
      </div>
     </div>
     <div class="fw-divider-space" style="margin-top: 10px;"></div>
    </div>
   </div>
  </div>
  <div class="bottom_corner_body"></div>
 </section>
