import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper';
import { Projet } from '../model/projet';
import { Techonologie } from '../model/technologie';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-detail-projet',
  templateUrl: './detail-projet.component.html',
  styleUrls: ['./detail-projet.component.css']
})
export class DetailProjetComponent implements OnInit {

  projet: Projet;
  apiUrl = environment.apiUrl;
  imUrl: string;

 //swipper config

 title = 'ng-swiper-demo';



 config: SwiperOptions = {
   pagination: { el: '.swiper-pagination', clickable: true },
   autoHeight: true,
   allowTouchMove: true,
   autoplay: {
     delay: 6000,
     disableOnInteraction: true
   },
   breakpoints: {
     1024: {
       slidesPerView: 8
     },
     500: {
       slidesPerView: 3
     },
     400: {
       slidesPerView: 2
     },
     300: {
       slidesPerView: 1
     }
   },
   navigation: {
     nextEl: '.swiper-button-next',
     prevEl: '.swiper-button-prev'
   },
  //  loop: true
 };

 resultats: any[] = [];

  constructor(private route: ActivatedRoute,
    private router: Location,
    private nav: Router,
    private service: AllService) {
    this.route.queryParams.subscribe((query) => {
      this.projet = JSON.parse(query.projet);
      console.log(this.projet);
      this.imUrl = this.apiUrl + "image/download/" + this.projet.path;
    });
  }

  ngOnInit(): void {
    window.scroll(0,0);
  }

  back() {
    this.router.back();
  }

  goToDetail(technologie: Techonologie) {
    let nav: NavigationExtras = {
      queryParams: {
        technologie: JSON.stringify(technologie)
      }
    }
    this.nav.navigate(['detail-technologie'], nav);
  }


}
