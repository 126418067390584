import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccueilComponent } from './accueil/accueil.component';
import { AllActualiteComponent } from './all-actualite/all-actualite.component';
import { AllProjetComponent } from './all-projet/all-projet.component';
import { DetailActuComponent } from './detail-actu/detail-actu.component';
import { DetailProjetComponent } from './detail-projet/detail-projet.component';
import { DetailTechnologieComponent } from './detail-technologie/detail-technologie.component';
import { LoginComponent } from './login/login.component';
import { OffreComponent } from './offre/offre.component';
import { ProposComponent } from './propos/propos.component';

const routes: Routes = [
  {
    component: AccueilComponent,
    path: '',
  },
  {
    component: AccueilComponent,
    path: 'accueil'
  },
  {
    component: DetailActuComponent,
    path: 'detail-actu'
  },
  {
    component: DetailProjetComponent,
    path: 'detail-projet'
  },
  {
    component: OffreComponent,
    path: 'offre/:type'
  },
  {
    component: AllActualiteComponent,
    path: 'all-actualite'
  },
  {
    component: AllProjetComponent,
    path: 'all-projet'
  },
  {
    component: DetailTechnologieComponent,
    path: 'detail-technologie'
  },
  {
    component: ProposComponent,
    path: 'propos'
  },
  {
    component: LoginComponent,
    path: 'login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
