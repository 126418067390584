import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Article } from '../model/article';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-offre',
  templateUrl: './offre.component.html',
  styleUrls: ['./offre.component.css']
})
export class OffreComponent implements OnInit {
  actualites: Article[] = [];
  apiUrl = environment.apiUrl;
  type: string;

  constructor(private nav: Router,
    private service: AllService,
    private location: Location,
    private route: ActivatedRoute) {
      this.type = this.route.snapshot.paramMap.get('type');
      this.getAllActualite(this.route.snapshot.paramMap.get('type'));
    }

  ngOnInit(): void {
  }

  getAllActualite(type) {
    this.service.getByType(type).subscribe((actu) => {
      this.actualites = actu;
      console.log(actu);
    });
  }

  goToDetail(actu) {
    let nav: NavigationExtras = {
      queryParams: {
        actu: JSON.stringify(actu)
      }
    };
    this.nav.navigate(['detail-actu'], nav);
    this.service.getWithoutCurrentArticle(actu.id).subscribe((res) => {
      console.log("without", res);
      this.actualites = res;
      window.scroll(0,0);
    });
  }

  back() {
    this.location.back();
  }
}
