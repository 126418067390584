import { Categorie } from "./categorie";
import { Commentaire } from "./commentaire";
import { User } from "./user";

export class Article {
    id?: number;
    titre: string;
    description: string;
    categorie: Categorie;
    type: string;
    user: User;
    path: string;
    photo: string;
    auteur: string;
    dateCreation: Date;
    dateModification: Date;
    supprime: boolean;
    alaune: boolean;
    commentaire: Commentaire[];
}
