import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Commentaire } from '../model/commentaire';
import { User } from '../model/user';
import { AllService } from '../services/all.service';

@Component({
  selector: 'app-comment-pop',
  templateUrl: './comment-pop.component.html',
  styleUrls: ['./comment-pop.component.scss']
})
export class CommentPopComponent implements OnInit {
  apiUrl = environment.apiUrl;
  commentaire: Commentaire = new Commentaire();
  // user: User = JSON.parse(sessionStorage.getItem('currentUser'));
  constructor(public dialogRef: MatDialogRef<CommentPopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: AllService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    setTimeout(() => {
      console.log(this.data);

    }, 500);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendComment() {
    this.commentaire.article = this.data;
    this.commentaire.dateCreation = new Date();
    this.commentaire.dateModification = new Date();
    this.commentaire.supprime = false;
    this.commentaire.user = JSON.parse(localStorage.getItem('currentUser'));
    setTimeout(() => {
      console.log(this.commentaire);

      this.service.saveComment(this.commentaire).subscribe((res) => {
        console.log(res);
        if(res.statut == true) {
          this.commentaire = new Commentaire();
        }
      }, (err) => {
        console.log(err);

      });
    }, 500);
  }
}
